require(['jquery'], function ($) {
  var codeUid = getCookie('codeUidAgent');
  var codeConcours = getCookie('codeAgent');
  $('.code-concours').text(codeConcours);
  $('a[href*="{id-token}"]').each(function (ind, lien) {
    var lienAvecParam = $(lien).attr('href').replace('{id-token}', codeUid);
    $(lien).attr('href', lienAvecParam);
  });
  function getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return '';
  }
});